import React, { useState, useEffect } from 'react';
import { Typography, ListItem, ListItemText, Grid, Paper, Box, makeStyles, Container, Button} from '@material-ui/core';
import classNames from 'classnames';
import Axios from 'axios';
import {useParams} from 'react-router';
import boxImg from '../../assets/images/formImages/box.svg';
import palletImg from '../../assets/images/formImages/wholesale.svg';
import truckImg from '../../assets/images/formImages/truck.svg';
import couchImg from '../../assets/images/formImages/couch.svg';
import trailerImg from '../../assets/images/formImages/trailer.svg';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 20,
    },
    paper: {
        backgroundColor: '#ebebeb',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(3),
        },
    }, 
    row: {
        padding: 8,
        [theme.breakpoints.down('sm')] : {
            borderBottom: '1px solid gray',
        }
      },
    cell: {
          flexDirection: 'column',
          alignItem: 'center',
          justifyContent: 'space-between',
          [theme.breakpoints.down('sm')] : {
              flexDirection: 'row'
          }
    },
    imgWidth: {
        width: 40,
        margin: '10px 0 0 20px'
    },
    titleSummary: {
        fontSize: 13,
    },
    button: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#f44336',
        color: '#fff',
        '&:hover': {
           backgroundColor: 'rgb(170, 46, 37)',
        }
    }
}))

function OrderDetail() {
    const classes = useStyles();
    const { id } = useParams();
    const [orderDetails, setOrderDetails] = useState([]);
    const history = useHistory();

    const getOrderDetails = async () => {
        let userId = localStorage.getItem('user-id');
        
        await Axios.get(`https://transport-szwecja.com/api/order/get?order_id=${id}&user_id=${userId}`, {
            auth: { username: 'shovv', password: '$HOVV2020' },
          }).then(({data}) => {
            setOrderDetails(data)
            //   console.log(data)
          });
    };

    useEffect(() => {
        getOrderDetails();
        // console.log('state', orderDetails)
    }, [])

    return (
        <Container>
            <Box mt={5}>
                <Typography variant="h5">{orderDetails?.shipmentDate?.substring(0,4)}/SF/{orderDetails?.orderId}/{orderDetails?.shipmentDate?.substring(5,7)}</Typography>
            </Box>
            <Box mt={5}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper} elevation={3}>
                            <Grid container direction="column"  justify="center" alignItems="flex-start" >
                                <Typography variant="h6" gutterBottom className={classes.title}>
                                    Dane adresowe nadawcy
                                </Typography>
                                <Grid item>
                                    <ListItem className={classes.listItem}>
                                    <ListItemText secondary='Imię i nazwisko lub nazwa firmy' primary={orderDetails.senderName} />
                                    </ListItem>
                                    <ListItem className={classNames(classes.listItem, classes.columnWidth)}>
                                    <ListItemText secondary='Adres odbioru przesyłki' primary={orderDetails.senderAddress} />
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                    <ListItemText secondary='Miasto' primary={orderDetails.senderCity} />
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                    <ListItemText secondary='Kod pocztowy' primary={orderDetails.senderZip} />
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                    <ListItemText secondary='Osoba do kontaktu' primary={orderDetails.senderContact} />
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                    <ListItemText secondary='Numer telefonu' primary={orderDetails.senderPhone} />
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                    <ListItemText secondary='Państwo' primary={orderDetails.senderCountry} />
                                    </ListItem>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper} elevation={3}>
                            <Grid container direction="column"  justify="center" alignItems="flex-start">
                                <Typography variant="h6" gutterBottom className={classes.title}>
                                Dane adresowe odbiorcy
                                </Typography>
                                <Grid item>
                                <ListItem className={classes.listItem}>
                                    <ListItemText secondary='Imię i nazwisko lub nazwa firmy' primary={orderDetails.recipientName} />
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemText secondary='Adres odbioru przesyłki' primary={orderDetails.recipientAddress} />
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemText secondary='Miasto' primary={orderDetails.recipientCity} />
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemText secondary='Kod pocztowy' primary={orderDetails.recipientZip} />
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemText secondary='Osoba do kontaktu' primary={orderDetails.recipientContact} />
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemText secondary='Numer telefonu' primary={orderDetails.recipientPhone} />
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <ListItemText secondary='Państwo' primary={orderDetails.recipientCountry} />
                                </ListItem>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid> 
            </Box>
            <Box mt={5}>
                <Paper  className={classes.paper} elevation={3}>
                    <Grid container>
                        <Grid xs={12} item container>
                            { 
                                orderDetails.packages ?
                                orderDetails.packages.map((p, idx) => {
                                return (
                                    <Grid container key={idx}>
                                    <Grid item xs={4} sm={1}>
                                        <img className={classes.imgWidth} src={boxImg} alt='icon'/>
                                    </Grid>
                                    <Grid className={classes.row} item container xs={12} sm={2}>
                                        <Grid item className={classes.cell} container >
                                        <Grid item >
                                            <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                            typ pakowania
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            {p.packageType == 1 ? <Typography variant="body2">karton lub koperta</Typography> : null}
                                            {p.packageType == 2 ? <Typography variant="body2">folia stretch</Typography> : null}
                                            {p.packageType == 3 ? <Typography variant="body2">folia bąbelkowa</Typography> : null}
                                            {p.packageType == 4 ? <Typography variant="body2">przesyłka niestandardowa</Typography> : null}
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.row} item container xs={12} sm={1}>
                                        <Grid item className={classes.cell} container >
                                            <Grid item >
                                            <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                waga
                                            </Typography>
                                            </Grid>
                                            <Grid item>
                                            <Typography variant="body2">{p.packageWeight + ' kg'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid> 
                                    <Grid className={classes.row} item container xs={12} sm={2}>
                                        <Grid item className={classes.cell} container >
                                        <Grid item >
                                            <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                            wysokość
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">{p.packageHeight + ' cm'}</Typography>
                                        </Grid>
                                        </Grid>
                                    </Grid> 
                                    <Grid className={classes.row} item container xs={12} sm={2}>
                                        <Grid item className={classes.cell} container >
                                            <Grid item >
                                            <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                Szerokość
                                            </Typography>
                                            </Grid>
                                            <Grid item>
                                            <Typography variant="body2">{p.packageWidth + ' cm'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid> 
                                    <Grid className={classes.row} item container xs={12} sm={2}>
                                        <Grid item className={classes.cell} container >
                                            <Grid item >
                                            <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                długość
                                            </Typography>
                                            </Grid>
                                            <Grid item>
                                            <Typography variant="body2">{p.packageLength + ' cm'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid> 
                                    <Grid className={classes.row} item container xs={12} sm={1}>
                                        <Grid item className={classes.cell} container >
                                            <Grid item >
                                            <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                ilość
                                            </Typography>
                                            </Grid>
                                            <Grid item>
                                            <Typography variant="body2">{p.packageAmount + ' cm'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid> 
                                    </Grid>
                                )
                                }) 
                                : null 
                            }

                            {
                                orderDetails.pallet ?
                                orderDetails.pallet.map((p, idx) => {
                                return (
                                    <Grid container key={idx}>
                                    <Grid item xs={4} sm={1}>
                                        <img className={classes.imgWidth} src={palletImg} alt='icon'/>
                                    </Grid>
                                    <Grid className={classes.row} item container xs={12} sm={2}>
                                        <Grid item className={classes.cell} container >
                                        <Grid item >
                                            <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                            rodzaj palety
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            {p.palletType == 1 ? <Typography variant="body2">europaleta</Typography> : null}
                                            {p.palletType == 2 ? <Typography variant="body2">inna</Typography> : null}
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.row} item container xs={12} sm={1}>
                                        <Grid item className={classes.cell} container >
                                            <Grid item >
                                            <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                waga
                                            </Typography>
                                            </Grid>
                                            <Grid item>
                                            <Typography variant="body2">{p.palletWeight + ' kg'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid> 
                                    <Grid className={classes.row} item container xs={12} sm={2}>
                                        <Grid item className={classes.cell} container >
                                            <Grid item >
                                            <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                wysokość
                                            </Typography>
                                            </Grid>
                                            <Grid item>
                                            <Typography variant="body2">{p.palletHeight + ' cm'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid> 
                                    <Grid className={classes.row} item container xs={12} sm={2}>
                                        <Grid item className={classes.cell} container >
                                            <Grid item >
                                            <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                szerokość
                                            </Typography>
                                            </Grid>
                                            <Grid item>
                                            <Typography variant="body2">{p.palletWidth + ' cm'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid> 
                                    <Grid className={classes.row} item container xs={12} sm={2}>
                                        <Grid item className={classes.cell} container >
                                            <Grid item >
                                                <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                    długość
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{p.palletLength + ' cm'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid> 
                                    <Grid className={classes.row} item container xs={12} sm={1}>
                                        <Grid item className={classes.cell} container >
                                            <Grid item >
                                            <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                ilość
                                            </Typography>
                                            </Grid>
                                            <Grid item>
                                            <Typography variant="body2">{p.palletAmount}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>                                                  
                                    </Grid>
                                )
                                })
                                : null
                            }
                            {
                                orderDetails.vehicles ?
                                orderDetails.vehicles.map((v, idx) => {
                                    return (
                                    <Grid container key={idx}>
                                        <Grid item xs={4} sm={1}>
                                        <img className={classes.imgWidth} src={truckImg} alt='icon'/>
                                        </Grid>
                                        <Grid className={classes.row} item container xs={12} sm={2}>
                                        <Grid item className={classes.cell} container >
                                            <Grid item >
                                            <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                rodzaj auta
                                            </Typography>
                                            </Grid>
                                            <Grid item>
                                            {v.vehicleType == 1 ? <Typography variant="body2">osobowy</Typography> : null}
                                            {v.vehicleType == 2 ? <Typography variant="body2">dostawczy</Typography> : null}
                                            </Grid>
                                        </Grid>
                                        </Grid>
                                        <Grid className={classes.row} item container xs={12} sm={1}>
                                            <Grid item className={classes.cell} container >
                                            <Grid item >
                                                <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                marka
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{v.vehicleBrand}</Typography>
                                            </Grid>
                                            </Grid>
                                        </Grid> 
                                        <Grid className={classes.row} item container xs={12} sm={2}>
                                            <Grid item className={classes.cell} container >
                                            <Grid item >
                                                <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                model
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{v.vehicleModel}</Typography>
                                            </Grid>
                                            </Grid>
                                        </Grid> 
                                        
                                        {v.vehicleWeight ?
                                        <Grid className={classes.row} item container xs={12} sm={2}>
                                            <Grid item className={classes.cell} container >
                                            <Grid item >
                                                <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                masa
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{v.vehicleWeight + ' kg'}</Typography>
                                            </Grid>
                                            </Grid>
                                        </Grid> : null }
            
                                        {v.vehicleLength ?
                                        <Grid className={classes.row} item container xs={12} sm={1}>
                                            <Grid item className={classes.cell} container >
                                            <Grid item >
                                                <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                długość
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{v.vehicleLength + ' cm'}</Typography>
                                            </Grid>
                                            </Grid>
                                        </Grid> : null }
                                    </Grid>  
                                    )
                                })
                                : null
                            }
                            {
                                orderDetails.furnitures ?
                                orderDetails.furnitures.map((f, idx) => {
                                    return (
                                    <Grid container key={idx}>
                                        <Grid item xs={4} sm={1}>
                                        <img className={classes.imgWidth} src={couchImg} alt='icon'/>
                                        </Grid>
            
                                        <Grid className={classes.row} item container xs={12} sm={2}>
                                            <Grid item className={classes.cell} container >
                                            <Grid item >
                                                <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                mebel
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{f.furnitureName}</Typography>
                                            </Grid>
                                            </Grid>
                                        </Grid> 
            
                                        <Grid className={classes.row} item container xs={12} sm={2}>
                                            <Grid item className={classes.cell} container >
                                            <Grid item >
                                                <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                ilość
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{f.furnitureAmount}</Typography>
                                            </Grid>
                                            </Grid>
                                        </Grid> 
                                    </Grid>
                                    )
                                })
                                : null
                            }
                            {
                                orderDetails.cargo ?
                                orderDetails.cargo.map((c,idx) => {
                                    return (
                                    <Grid container key={idx}>
                                        <Grid item xs={4} sm={1}>
                                        <img className={classes.imgWidth} src={trailerImg} alt='icon'/>
                                        </Grid>
                                    
                                        <Grid className={classes.row} item container xs={12} sm={2}>
                                            <Grid item className={classes.cell} container >
                                            <Grid item >
                                                <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                twój ładunek
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{c.cargoName}</Typography>
                                            </Grid>
                                            </Grid>
                                        </Grid> 
            
                                        <Grid className={classes.row} item container xs={12} sm={2}>
                                            <Grid item className={classes.cell} container >
                                            <Grid item >
                                                <Typography className={classes.titleSummary} variant="h6" gutterBottom>
                                                ilość
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{c.cargoAmount}</Typography>
                                            </Grid>
                                            </Grid>
                                        </Grid> 
                                    </Grid>
                                    )
                                })
                                : null
                            }

                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <Grid><Button className={classes.button} variant="contained" onClick={() => history.push('/konto')}>Cofnij</Button></Grid>
        </Container> 
  
    )
}

export default OrderDetail
