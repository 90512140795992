import { Button, Box, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import Login from '../../../auth/Login';
import Register from '../../../auth/Register';
import userContext from '../../../context/userContext';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  redBold: {
    fontWeight: 600,
  },
  title: {
    textTransform: 'none',
    fontSize: 24,
  }
})

function UserOfflineAction() {
  const { filledEmailInfo, userData } = useContext(userContext);
  const history = useHistory();
  const classes = useStyles()
  // console.log('info odnosnie emaila wpisanego w formie', filledEmailInfo);
  // console.log('sprawdzenie contextu po zalogowaniu', userData.user)
  return (
    <>
      {filledEmailInfo === 3 ? (
        <>
          {history.push('/rejestracja')}
        </>
      ) : null}
      {filledEmailInfo === 1 ? (
        <h1>
         Twoje konto jest nieaktywne. Wejdź w link aktywacyjny, który wysłaliśmy na Twojego emaila
        </h1>
      ) : null}
      {filledEmailInfo === 2 ? (
        <h5>2 = uzytkownik zablokowany - Informacja o zablokowaniu</h5>
      ) : null}
      {filledEmailInfo === 5 ? (
        <>
            {
              userData.user === undefined ? (
                <>
                  <Box mt={5} textAlign="center">
                    <Typography variant="body2" className={classes.title} gutterBottom>
                      Dziekujemy za wypełnienie formularza, już prawie koniec!
                    </Typography>
                    <Typography className={classes.redBold} variant="body2" color="error">
                      Zaloguj się, aby dokończyć wycenę.
                    </Typography>
                    <p>W razie pytań możesz skontaktować się z nami pod numerem: +48 735 130 862 </p>
                  </Box>
                  <Box mt={5}>
                    <Login />
                  </Box>
                </>
              ) : null
            }
        </>
      ) : null}
    </>
  );
}

export default UserOfflineAction;
