import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../context/userContext';
import './UserPanel.css';
import {Container} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import {Button, Typography} from '@material-ui/core';


const useStyles = makeStyles({
    table: {
        margin: '50px 0 ',
        minWidth: 650,
    },
    tableCell: {
        borderBottom: '1px solid black',
    },
});


function UserPanel() {
    const { userData } = useContext(UserContext);
    const history = useHistory();
    const [orders, setOrders] = useState([]);
    const [idOrder, setIdOrder] = useState();

    useEffect(() => {
          //przypisanie orderu do uzytkownika ktory sie loguje
          let orderId = localStorage.getItem('order_id')
          let userId = localStorage.getItem('user-id')
                
          if(orderId === null) {
              localStorage.setItem('order_id', '');
              orderId = '';
          }
          const data = {"order_id": orderId, "user_id": userId }
          
          Axios.post('https://transport-szwecja.com/api/orders/assign', data,  {
            headers: { 
              'Content-Type': 'application/json'
            },
            auth: {
              username: 'shovv', 
              password: '$HOVV2020'
            }
            })
        .then(res => {
            console.log('PRZYPISANIE ORDER DO UZYTKOWNIKA', res)
            return (
                getUserOrders()
            )
        })


        const getUserOrders = async () => {
            // let userId = localStorage.getItem('user-id');
            console.log('render komponentu Panelu uzytkownika start')
            await Axios.get(`https://transport-szwecja.com/api/orders/get?user_id=${userId}`, {
              auth: { username: 'shovv', password: '$HOVV2020' },
            }).then(({data}) => {
                setOrders(data.orders)    
                console.log('render komponentu po sciagnieciu zamowien uzytkownika')
            });
          };
        //   getUserOrders();

    }, []);

        const classes = useStyles()

      return (

        <div className="user-panel--wrapper">
            <Container>
            <Box mt={5}>
                <Typography variant="h5">Moje konto</Typography>
                </Box>
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCell}>Lp</TableCell>
                            <TableCell className={classes.tableCell} align="right">Numer zamówienia</TableCell>
                            <TableCell className={classes.tableCell} align="right">Rodzaj ładunku</TableCell>
                            <TableCell className={classes.tableCell} align="right">nadawca</TableCell>
                            <TableCell className={classes.tableCell} align="right">odbiorca</TableCell>
                            <TableCell className={classes.tableCell} align="right">Status</TableCell>
                            <TableCell className={classes.tableCell} align="right">Szczegóły zamówienia</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {orders.map(({orderId, orderStatus, packages, pallet, furnitures, vehicles, cargo, recipientName, senderName, shipmentDate}, idx) => (
                            <TableRow key={orderId}>
                            <TableCell className={classes.tableCell} component="th" scope="row">
                                {idx+1}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="right">{shipmentDate.substring(0,4)}/SF/{orderId}/{shipmentDate.substring(5,7)}</TableCell>
                            <TableCell className={classes.tableCell} align="right">
                                {packages.length ? <Typography variant="body2">Paczka</Typography> : null}
                                {pallet.length ? <Typography variant="body2">Paleta</Typography> : null}
                                {furnitures.length ? <Typography variant="body2">Meble</Typography> : null}
                                {cargo.length ? <Typography variant="body2">Trasnport ponadgabarytowy</Typography> : null}
                                {vehicles.length ? <Typography variant="body2">Auto</Typography> : null}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="right">{senderName}</TableCell>
                            <TableCell className={classes.tableCell} align="right">{recipientName}</TableCell>
                            <TableCell className={classes.tableCell} align="right">
                                {orderStatus == 1 ? <Typography variant="body2">Nowe zamówienie</Typography> : null}
                                {orderStatus == 2 ? <Typography variant="body2">W trakcie realizacji</Typography> : null}
                                {orderStatus == 3 ? <Typography variant="body2" >Zakończone</Typography> : null}
                                {orderStatus == 4 ? <Typography variant="body2" color="error">Anulowane</Typography> : null}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="right">
                                <Button onClick={() => history.push(`/konto/${orderId}`)} variant="contained">Szczegóły</Button></TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </div>
    )
}




export default UserPanel
